export const PinchScale = {
  schema: {
    min: { default: 0.75 },
    max: { default: 2.25 },
    scale: { default: 0 } // If scale is set to zero here, the object's initial scale is used.
  },
  init () {
    const s = this.data.scale
    this.initialScale = (s && { x: s, y: s, z: s }) || this.el.object3D.scale.clone()
    this.quarter = document.getElementById('quarter')
    this.fade = 'out'
    this.scaleFactor = 1
    this.handleEvent = this.handleEvent.bind(this)
    // this.fadeOut = this.fadeOut.bind(this)
    this.el.sceneEl.addEventListener('twofingermove', this.handleEvent)
    this.el.sceneEl.addEventListener('touchend', () => { this.fade = 'out' })
    this.el.classList.add('cantap') // Needs "objects: .cantap" attribute on raycaster.
  },
  remove () {
    this.el.sceneEl.removeEventListener('twofingermove', this.handleEvent)
  },
  handleEvent (event) {
    this.scaleFactor *= 1 + event.detail.spreadChange / event.detail.startSpread
    this.scaleFactor = Math.min(Math.max(this.scaleFactor, this.data.min), this.data.max)
    this.fade = 'in'
    this.el.object3D.scale.x = this.scaleFactor * this.initialScale.x
    this.el.object3D.scale.y = this.scaleFactor * this.initialScale.y
    this.el.object3D.scale.z = this.scaleFactor * this.initialScale.z
  },
  tick () {
    if (this.fade === 'out') {
      if (this.quarter.object3D.children[0].material.opacity === 0) return
      this.quarter.object3D.children[0].material.opacity = THREE.MathUtils.lerp(this.quarter.object3D.children[0].material.opacity, 0, 0.2)
    } else if (this.fade === 'in') {
      if (this.quarter.object3D.children[0].material.opacity === 1) return
      this.quarter.object3D.children[0].material.opacity = THREE.MathUtils.lerp(this.quarter.object3D.children[0].material.opacity, 1, 0.2)
    }
  }
}
