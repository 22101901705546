// ///////
// Imports
// ///////

// HTML
import * as googleTagManagerHtml from './lib/views/gtm.html'
import * as guiHtml from './lib/views/gui.html'
import * as customLoadingHtml from './lib/views/custom-loading.html'
import * as bodyHtml from './body.html'
import * as visualizerHtml from './lib/views/visualizer.html'
// Styles
// import './lib/styles/vars.scss'
import './lib/styles/index.scss'
import './lib/styles/animations.scss'
import './lib/styles/loading.scss'
import './lib/styles/custom-loading.scss'
// import './lib/styles/joystick.scss'

// //////////////////////////////////
// Component/Shader/Primitive Import
// /////////////////////////////////
import { CustomLoading } from './lib/components/aircards/custom-loading'
import { DesktopDebug } from './lib/components/aircards/desktop-debug.js'
import { EternevaFlow } from './lib/components/eterneva-flow'
import { BackgroundVideos } from './lib/components/bg-videos'
import { ArPassthrough } from './lib/components/ar-passthrough'
import { Visualizer } from './lib/components/visualizer'
import { OneFingerTumble } from './lib/components/one-finger-tumble'
import { Colorize } from './lib/components/aircards/colorize'
import { DynamicOpacity } from './lib/components/dynamic-opacity'
import { PinchScale } from './lib/components/pinch-scale'
import { checkOrientation } from './lib/util/orientation'

// Catch-all to prevent iOS page zoom
document.body.style = 'touch-action: none;'
window.addEventListener('gesturestart', function (e) {
  e.preventDefault()
  e.stopPropagation()
})

window.addEventListener('dblclick', function (e) {
  e.preventDefault()
  e.stopPropagation()
})

// /////////////
// HTML Injection
// /////////////
document.body.insertAdjacentHTML('afterbegin', googleTagManagerHtml)
document.body.insertAdjacentHTML('afterbegin', customLoadingHtml)

// //////////////////
// Component Registry
// //////////////////

let aircardsComponents
const queryParams = new URLSearchParams(window.location.search)
const scene = queryParams.get('s')

if (scene === 'visualizer') {
  document.body.insertAdjacentHTML('afterbegin', visualizerHtml)
  aircardsComponents = {
    'ar-passthrough': ArPassthrough,
    visualizer: Visualizer,
    'one-finger-tumble': OneFingerTumble,
    colorize: Colorize,
    'custom-loading': CustomLoading,
    'desktop-debug': DesktopDebug,
    'pinch-scale': PinchScale
  }
} else {
  document.body.insertAdjacentHTML('afterbegin', bodyHtml)
  document.body.insertAdjacentHTML('afterbegin', guiHtml)
  checkOrientation()
  window.addEventListener('resize', checkOrientation)
  aircardsComponents = {
    'eterneva-flow': EternevaFlow,
    'bg-videos': BackgroundVideos,
    'custom-loading': CustomLoading,
    'desktop-debug': DesktopDebug,
    'dynamic-opacity': DynamicOpacity
  }
}

// const aircardsShaders = {
//   line: LineShader
// }

// const aircardsPrimitives = {
//   'a-draw-curve': ADrawCurvePrimitive,
//   'a-curve-point': ACurvePointPrimitive,
//   'a-curve': ACurvePrimitive
// }

const registerComponents = components =>
  Object.keys(components).map(k =>
    AFRAME.registerComponent(k, components[k]))

// const registerShaders = shaders =>
//   Object.keys(shaders).map(k =>
//     AFRAME.registerShader(k, shaders[k]))

// const registerPrimitives = primitives =>
//   Object.keys(primitives).map(k =>
//     AFRAME.registerPrimitive(k, primitives[k]))

registerComponents(aircardsComponents)

// ///////////////////////////////////
// 8th Wall Load Screen Customizations
// ///////////////////////////////////

// Inject "8th Wall + Aircards" svg on load screen
let inDom = false
const observer = new MutationObserver((mutations) => {
  if (document.querySelector('.poweredby-img')) {
    if (!inDom) {
      document.querySelector('.poweredby-img').src = require('./assets/images/brand/poweredby_horiz_white_withAR.svg')
    }
    inDom = true
  } else if (inDom) {
    inDom = false
    observer.disconnect()
  }
})
observer.observe(document.body, { childList: true })

// Inject custom loading HTML elements
let inDom2 = false
const legalText = '<p id="legalText">© Copyright Aircards 2021. All Rights Reserved. Replace me with brand copy!.</p>'

const observer2 = new MutationObserver((mutations) => {
  const loadContainer = document.getElementById('loadImageContainer')
  if (loadContainer) {
    if (!inDom2) {
      loadContainer.classList.add('noselect')
      loadContainer.insertAdjacentHTML('beforeend', legalText)
      // Edit the iOS motion prompt text
      if (document.querySelector('.prompt-box-8w')) {
        document.querySelector('.prompt-box-8w p').innerHTML = '<strong>Please allow Aircards access to your device\'s sensors to enable AR features.'
      }
    }
    inDom2 = true
  } else if (inDom) {
    inDom2 = false
    observer2.disconnect()
  }
})
observer2.observe(document.body, { childList: true })

// Hide 8th wall spinner
// let inDom3 = false
// const observer3 = new MutationObserver(((mutations) => {

//   if (document.getElementById('loadImage')) {
//     if (!inDom3) {
//       document.getElementById('loadImage').style.opacity = '0' // Hide spinner
//     }
//     inDom3 = true
//   } else if (inDom3) {
//     inDom3 = false
//     observer3.disconnect()
//   }
// }))
// observer3.observe(document.body, {childList: true})
