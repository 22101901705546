const toUrl = (urlOrId) => {
  const img = document.querySelector(urlOrId)

  return img ? img.src : urlOrId
}

export const Colorize = {
  schema: {
    posx: { default: '#posx' },
    posy: { default: '#posy' },
    posz: { default: '#posz' },
    negx: { default: '#negx' },
    negy: { default: '#negy' },
    negz: { default: '#negz' },
    extension: { default: 'png', oneOf: ['jpg', 'png'] },
    color: { type: 'string', default: '#2222ff' },
    nodeNames: { type: 'string', default: '' } // Omit this param to occlude the entire mesh
  },
  init () {},
  update () {
    const model = this.el.getObject3D('mesh')
    if (model) {
      this.colorize(model)
    } else {
      this.el.addEventListener(
        'model-loaded',
        (e) => {
          this.colorize(e.detail.model)
        }
      )
    }
  },

  // ///////////////////////////////////
  // Usage Notes:
  // - The texture (if any) is multiplied by the set color
  // - If the scene renderer param `colorManagement` is true, use ``
  // - The same material of the GLTF may be shared across multiple objects, causing all to change
  //   - Use newMaterial to avoid this
  // ///////////////////////////////////

  colorize (model) {
    const { nodeNames } = this.data
    let { color } = this.data
    if (color.charAt(0) !== '#') color = `#${color}` // Add a # if the color doesn't have one
    this.envMap = new THREE.CubeTextureLoader().load([

      toUrl(this.data.posx), toUrl(this.data.negx),

      toUrl(this.data.posy), toUrl(this.data.negy),

      toUrl(this.data.posz), toUrl(this.data.negz)

    ])
    this.envMap.mapping = THREE.CubeRefractionMapping

    model.traverse((node) => {
      if (!node.isMesh) return

      // Color the whole model
      if (!nodeNames) {
        // The texture is multiplied by the color here, then corrected for scene colorManagement
        node.material.color.set(color)
        node.material.skinning = true
        node.material.envMap = this.envMap
        // console.log(node.material.map)
        // node.material.color.set(color)
        node.material.skinning = true
        // node.material.envMap = this.envMap
        // node.material.transparent = true
        // node.material.side = THREE.DoubleSide
        // node.material.metalness = 0.7
        node.material.morphTargets = true
        node.material.needsUpdate = true
      }

      // Target specific nodes
      if (nodeNames.includes(node.name)) {
        // console.log(node.name, nodeNames)
        node.material.color.set(color)
        node.material.skinning = true
        node.material.envMap = this.envMap
        // node.material.transparent = true
        // node.material.side = THREE.DoubleSide
        // node.material.metalness = 0.7
        node.material.morphTargets = true
        node.material.needsUpdate = true
      }
    })
  }
}
