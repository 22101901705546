export const BackgroundVideos = {
  schema: {
    active: { type: 'string', default: 'broll1' },
    isStatic: { type: 'boolean', default: false }
  },
  init () {
    this.model = null
    this.mixer = null
    this.videoEl = document.getElementById('brollSrc')
    const model = this.el.getObject3D('mesh')
    if (model) {
      this.model = model
      this.update()
    } else {
      this.el.addEventListener(
        'model-loaded',
        (e) => {
          this.model = e.detail.model
          this.update()
        }
      )
    }
  },

  update () {
    if (!this.model) return
    if (!this.data.isStatic) {
      const texture = new THREE.VideoTexture(this.videoEl)
      texture.minFilter = THREE.LinearFilter
      texture.magFilter = THREE.LinearFilter
      texture.format = THREE.RGBFormat
      texture.wrapS = THREE.ClampToEdgeWrapping
      texture.wrapT = THREE.ClampToEdgeWrapping
      texture.flipY = false
      texture.encoding = THREE.sRGBEncoding

      const overMat = new THREE.MeshBasicMaterial({
        map: texture
      })
      // Go over the submeshes and modify materials we want.
      this.model.traverse((node) => {
        if (node.isMesh) {
          if (node.name === this.data.active) {
            node.visible = true
            // APPLY THE VIDEO TEXTURE
            node.material = overMat
            node.material.map = texture
            node.material.needsUpdate = true
          } else if (node.name !== 'broll11') {
            if (node.name.includes('broll')) {
              node.visible = false
            }
          }
        }
      })
    } else {
      this.model.traverse((node) => {
        if (node.isMesh) {
          if (node.name === this.data.active) {
            node.visible = true
          } else if (node.name !== 'broll11') {
            if (node.name.includes('broll')) {
              node.visible = false
            }
          }
        }
      })
    }

    // })
  }
}
