export const hideElem = async (elem, callback) => {
  elem.classList.remove('fadeIn')
  elem.classList.add('fadeOut')
  setTimeout(() => {
    elem.style.display = 'none'
    if (callback) {
      callback()
    }
  }, 1000)
}

export const showElem = (elem, display, callback) => {
  elem.classList.remove('fadeOut')
  elem.classList.add('fadeIn')
  elem.style.display = display
  if (callback) {
    setTimeout(() => {
      callback()
    }, 1000)
  }
}

export const removeElem = (elem, callback) => {
  elem.classList.remove('fadeIn')
  elem.classList.add('fadeOut')
  setTimeout(() => {
    elem.remove()
    if (callback) {
      callback()
    }
  }, 1000)
}
