export const DynamicOpacity = {
  init () {
    const { el } = this
    const model = el.getObject3D('mesh')
    this.nodesToUpdate = []
    if (model) {
      this.load(model)
    } else {
      this.el.addEventListener('model-loaded', (e) => {
        this.load(e.detail.model)
      }, { once: true })
    }
    this.load = this.load.bind(this)
    this.fade = this.fade.bind(this)
    this.throttledFade = AFRAME.utils.throttle(this.fade, 1000)
  },
  load (model) {
    if (!model) return
    model.traverse((node) => {
      if (node.isMesh && node.name.includes('blank_panel_')) {
        const color = node.material.color
        node.material = new THREE.MeshBasicMaterial()
        node.material.color = color
        node.material.transparent = true
        node.material.alphaTest = 0.25
        // node.material.blending = THREE.AdditiveBlending
        node.material.skinning = true
        node.material.morphTargets = true
        node.material.needsUpdate = true
        this.nodesToUpdate.push(node)
      }
    })
    this.fade()
  },
  fade (t, dt) {
    let i = this.nodesToUpdate.length
    while (i--) {
      const opc = Math.random()
      this.nodesToUpdate[i].targetOpacity = opc
    }
  },
  tick (t, dt) {
    this.throttledFade(t, dt)
    let i = this.nodesToUpdate.length
    while (i--) {
      this.nodesToUpdate[i].material.opacity = THREE.MathUtils.lerp(this.nodesToUpdate[i].material.opacity, this.nodesToUpdate[i].targetOpacity, 0.01)
      this.nodesToUpdate[i].material.needsUpdate = true
    }
  }
}
