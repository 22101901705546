export const checkOrientation = () => {
  const isLandscape = () => (window.innerWidth > window.innerHeight)
  const displayAlert = () => {
    clearTimeout(window.alertTimeout)
    const alert = document.getElementById('alert')
    alert.style.display = 'block'
    alert.className = 'fadeIn'
  }

  const hideAlert = () => {
    clearTimeout(window.alertTimeout)
    const alert = document.getElementById('alert')
    alert.className = 'fadeOut'
    window.alertTimeout = setTimeout(() => {
      alert.style.display = 'none'
    }, 1000)
  }
  if (isLandscape()) {
    displayAlert()
  } else {
    hideAlert()
  }
}
