// Imports
var ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___ = require("../../../node_modules/html-loader/dist/runtime/getUrl.js");
var ___HTML_LOADER_IMPORT_0___ = require("../../assets/images/brand/eterneva_logo_main.png");
var ___HTML_LOADER_IMPORT_1___ = require("../../assets/images/brand/ar_logo.svg");
var ___HTML_LOADER_IMPORT_2___ = require("../../assets/images/brand/poweredby_horiz_white_withAR.svg");
// Module
var ___HTML_LOADER_REPLACEMENT_0___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_0___);
var ___HTML_LOADER_REPLACEMENT_1___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_1___);
var ___HTML_LOADER_REPLACEMENT_2___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_2___);
var code = "<div id=\"c-l-container\" class=\"noselect\"> <div id=\"c-l-loading\"> <i id=\"c-l-spinner\" class=\"fad fa-spinner rotate\"></i> <img id=\"c-l-loadingImg\" src=\"" + ___HTML_LOADER_REPLACEMENT_0___ + "\"/> </div> <div id=\"c-l-prompt\"> <strong>Please allow Aircards access to your device's sensors to enable AR features.</strong> <br/> <button id=\"c-l-deny\">Deny</button><button id=\"c-l-continue\">Continue</button> </div> <div id=\"c-l-qrContainer\"> <div id=\"c-l-qrCode\"> <canvas id=\"c-l-qrCanvas\" style=\"width:100%;height:100%\"></canvas> </div> <div id=\"c-l-qrPrompt\"> Scan code or navigate to <p id=\"c-l-qrLink\" class=\"userSelect\"></p> on a mobile phone. </div> </div> <img id=\"c-l-arLogo\" src=\"" + ___HTML_LOADER_REPLACEMENT_1___ + "\"/> <img id=\"c-l-poweredByLogo\" src=\"" + ___HTML_LOADER_REPLACEMENT_2___ + "\"> <img id=\"c-l-clientLogo\"/> <div id=\"c-l-legalText\"> &copy; Copyright Eterneva 2022. All Rights Reserved. </div> <div id=\"c-l-fallback\"> <i class=\"fas fa-cogs\"></i> <p>Please enable camera access for this site in your browser settings and then restart your browser.</p> </div> </div>";
// Exports
module.exports = code;