const QRCode = require('qrcode')
export const CustomLoading = {
  schema: {
    is8wBranded: { type: 'boolean', default: true },
    loadImage: { type: 'selector', default: '#loadingImage' },
    loadAnimation: { type: 'string', default: 'scale' },
    arPassthrough: { type: 'boolean', default: false },
    permissions: { type: 'array', default: ['orientation'] }
  },

  init () {
    const scene = this.el.sceneEl
    const camera = document.getElementById('camera')
    const aAssets = document.querySelector('a-assets')

    // ///////////
    // UI ELEMENTS
    // ///////////

    const qrCanvas = document.getElementById('c-l-qrCanvas')
    const qrContainer = document.getElementById('c-l-qrContainer')
    const qrLink = document.getElementById('c-l-qrLink')
    const arLogo = document.getElementById('c-l-arLogo')
    const poweredBy = document.getElementById('c-l-poweredByLogo')
    const clientLogo = document.getElementById('c-l-clientLogo')

    this.clp = document.getElementById('c-l-prompt')
    this.clc = document.getElementById('c-l-continue')
    this.cld = document.getElementById('c-l-deny')
    this.container = document.getElementById('c-l-container')
    this.loading = document.getElementById('c-l-loading')
    this.spinner = document.getElementById('c-l-spinner')
    this.loadingImg = document.getElementById('c-l-loadingImg')
    this.fallback = document.getElementById('c-l-fallback')

    scene.setAttribute('vr-mode-ui', 'enabled: false')
    scene.setAttribute('device-orientation-permission-ui', 'enabled: false')
    camera.setAttribute('look-controls', { touchEnabled: false })

    // ////////////
    // BIND METHODS
    // ////////////
    this.revealScene = this.revealScene.bind(this)
    this.showPermissionsFallback = this.showPermissionsFallback.bind(this)
    this.confirmSceneReady = this.confirmSceneReady.bind(this)
    this.showLoadingImg = this.showLoadingImg.bind(this)
    this.requestPermissions = this.requestPermissions.bind(this)
    this.handlePermissions = this.handlePermissions.bind(this)

    // //////////////
    // PLATFORM LOGIC
    // //////////////

    // if device is a mobile phone
    this.isMobile = /IEMobile|Windows Phone|Lumia|iPhone|iPad|iPod|Android|Blackberry|Playbook|BB10|Mobile Safari|webOs|Mobile|Tablet|Opera Mini|Opera Mobi/i.test(navigator.userAgent)
    // if device orientation permissions have been granted by the user (always true on android)
    this.permissionsGranted = false
    // if a-assets have finished loading
    this.assetsLoaded = false

    // handle loading image
    this.loadingImg.src = this.data.loadImage.src
    // handle desktop
    if (!this.isMobile) {
      // handle qr code
      qrContainer.classList.add('fadeIn')
      qrContainer.style.display = 'flex'
      QRCode.toCanvas(qrCanvas, window.location.href, function (error) {
        if (error) console.error(error)
        console.log('qrcode generated')
      })
      qrLink.innerText = window.location.href
      this.loading.remove()
      if (!this.data.is8wBranded) {
        // handle client logo
        arLogo.style.height = '70px'
        clientLogo.src = this.loadingImg.src
        clientLogo.style.display = 'block'
        clientLogo.classList.add('fadeIn')
      }
    }

    // handle powered by logo
    if (this.data.is8wBranded) {
      poweredBy.classList.add('fadeIn')
      poweredBy.style.display = 'block'
    } else {
      arLogo.classList.add('fadeIn')
      arLogo.style.display = 'block'
    }

    // handle device permissions
    this.requestPermissions()

    // show loading image when ready
    if (this.loadingImg.complete || this.loadingImg.naturalHeight !== 0) {
      this.showLoadingImg()
    } else {
      this.loadingImg.addEventListener('load', () => {
        this.showLoadingImg()
      })
    }

    // ensure all a-assets are loaded
    if (aAssets.hasLoaded) {
      this.assetsLoaded = true
      this.confirmSceneReady()
    } else {
      aAssets.addEventListener('loaded', () => {
        this.assetsLoaded = true
        this.confirmSceneReady()
      })
    }
  },

  requestPermissions () {
    if (typeof DeviceOrientationEvent === 'undefined' || !DeviceOrientationEvent.requestPermission) {
      // handle regular non iOS 13+ devices
      this.permissionsGranted = true
      if (this.data.arPassthrough) {
        this.el.sceneEl.setAttribute('ar-passthrough', '')
      }
    } else {
      // handle iOS 13+ devices
      this.clp.classList.add('fadeIn')
      this.clp.style.display = 'flex'
      this.clc.addEventListener('click', () => {
        this.clp.remove()
        this.handlePermissions()
        if (this.data.arPassthrough) {
          this.el.sceneEl.setAttribute('ar-passthrough', '')
        }
      })
      this.cld.addEventListener('click', () => {
        this.clp.remove()
        this.showPermissionsFallback()
      })
    }
  },

  handlePermissions () {
    DeviceOrientationEvent.requestPermission()
      .then((permissionState) => {
        if (permissionState === 'granted') {
          this.permissionsGranted = true
          this.confirmSceneReady()
        } else {
          this.showPermissionsFallback()
        }
      })
      .catch(() => {
        this.showPermissionsFallback()
      })
  },

  showLoadingImg () {
    this.spinner.style.display = 'none'
    this.loadingImg.className = `fadeIn ${this.data.loadAnimation}`
    this.loadingImg.style.display = 'block'
  },

  confirmSceneReady () {
    if (this.permissionsGranted && this.assetsLoaded && this.isMobile) {
      this.revealScene()
    }
  },

  revealScene () {
    setTimeout(() => {
      this.container.classList.add('fadeOutFast')
      setTimeout(() => {
        this.container.remove()
      }, 500)
    }, 3000) //  delay because textures are not always ready after a-assets are loaded
  },

  showPermissionsFallback () {
    this.fallback.classList.add('fadeIn')
    this.fallback.style.display = 'flex'
  }
}
