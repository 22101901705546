export const Visualizer = {
  init () {
    this.diamond = document.getElementById('diamond')
    this.camera = document.getElementById('camera')
    this.diamondGroup = document.getElementById('diamondGroup')
    const dots = Array.from(document.getElementsByClassName('dot'))
    const colors = Array.from(document.getElementsByClassName('colorSelect'))
    const cuts = Array.from(document.getElementsByClassName('cutSelect'))
    this.setScale = this.setScale.bind(this)
    this.setColor = this.setColor.bind(this)
    this.componentFromStr = this.componentFromStr.bind(this)
    this.rgbToHex = this.rgbToHex.bind(this)
    dots.forEach((dot) => {
      const circle = dot.querySelector('.circle')
      switch (circle.className.replace('circle ', '')) {
        case 'sm':
          dot.addEventListener('click', () => {
            window.dataLayer.push({ event: 'Viz Size Selected SM' })
            this.setScale('0.4, 0.4, 0.4')
            dots.forEach(d => d.querySelector('.circle').classList.remove('active'))
            circle.classList.add('active')
          })

          break
        case 'md':
          dot.addEventListener('click', () => {
            window.dataLayer.push({ event: 'Viz Size Selected MD' })
            this.setScale('0.6, 0.6, 0.6')
            dots.forEach(d => d.querySelector('.circle').classList.remove('active'))
            circle.classList.add('active')
          })
          break
        case 'lg':
          dot.addEventListener('click', () => {
            window.dataLayer.push({ event: 'Viz Size Selected LG' })
            this.setScale('0.8, 0.8, 0.8')
            dots.forEach(d => d.querySelector('.circle').classList.remove('active'))
            circle.classList.add('active')
          })
          break
        case 'xl':
          dot.addEventListener('click', () => {
            window.dataLayer.push({ event: 'Viz Size Selected XL' })
            this.setScale('1, 1, 1')
            dots.forEach(d => d.querySelector('.circle').classList.remove('active'))
            circle.classList.add('active')
          })
          break
      }
    })
    colors.forEach((color) => {
      color.addEventListener('click', () => {
        window.dataLayer.push({ event: `Viz Color Selected ${color.id.charAt(0).toUpperCase() + color.id.slice(1)}` })
        this.setColor(color)
      })
    })
    cuts.forEach((cut) => {
      cut.addEventListener('click', () => {
        window.dataLayer.push({ event: `Viz Cut Selected ${cut.id.charAt(0).toUpperCase() + cut.id.slice(1)}` })
        const diamond = document.createElement('a-entity')
        diamond.id = 'diamond'
        diamond.setAttribute('gltf-model', `#${cut.id}Model`)
        diamond.setAttribute('scale', this.diamond.getAttribute('scale'))
        diamond.setAttribute('one-finger-tumble', '')
        diamond.setAttribute('colorize', this.diamond.getAttribute('colorize'))
        diamond.setAttribute('position', this.diamond.getAttribute('position'))
        this.diamond.parentNode.removeChild(this.diamond)
        this.diamondGroup.appendChild(diamond)
        this.diamond = document.getElementById('diamond')
      })
    })
  },
  setScale (scale) {
    this.diamond.setAttribute('scale', scale)
  },
  setColor (color) {
    const cssObj = window.getComputedStyle(color, null)
    const bgColor = cssObj.getPropertyValue('background-color')
    this.diamond.setAttribute('colorize', `color: ${this.rgbToHex(bgColor)};`)
  },
  // https://stackoverflow.com/questions/13070054/convert-rgb-strings-to-hex-in-javascript
  componentFromStr (numStr, percent) {
    const num = Math.max(0, parseInt(numStr, 10))
    return percent
      ? Math.floor(255 * Math.min(100, num) / 100)
      : Math.min(255, num)
  },
  rgbToHex (rgb) {
    const rgbRegex = /^rgb\(\s*(-?\d+)(%?)\s*,\s*(-?\d+)(%?)\s*,\s*(-?\d+)(%?)\s*\)$/
    let result; let r; let g; let b; let hex = ''
    if ((result = rgbRegex.exec(rgb))) {
      r = this.componentFromStr(result[1], result[2])
      g = this.componentFromStr(result[3], result[4])
      b = this.componentFromStr(result[5], result[6])

      hex = '#' + (0x1000000 + (r << 16) + (g << 8) + b).toString(16).slice(1)
    }
    return hex
  }
}
