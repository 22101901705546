export const ArPassthrough = {
  init () {
    const constraints = {
      video: {
        facingMode: 'environment',
        width: 1280,
        height: 720,
        audio: false
      }
    }
    const link = document.createElement('link')
    link.src = require('../styles/ar-passthrough.scss')
    document.head.appendChild(link)
    const video = document.createElement('video')
    video.id = 'ar-passthrough-video'
    video.setAttribute('autoplay', '')
    video.setAttribute('playsinline', '')
    video.setAttribute('playsinline', '')
    navigator.mediaDevices.getUserMedia(constraints)
      .then((stream) => { video.srcObject = stream; this.stream = stream }).catch(e => console.error)
    const aAssets = document.querySelector('a-assets')
    aAssets.appendChild(video)
    this.videoSrc = document.getElementById('ar-passthrough-video')
    const camera = document.getElementById('camera')
    const videoPlane = document.createElement('a-plane')
    videoPlane.setAttribute('material', {
      src: '#ar-passthrough-video',
      shader: 'flat'
    })
    video.muted = true
    video.play()
    videoPlane.id = 'arVideoPlane'
    videoPlane.setAttribute('position', '0 0 -5')
    videoPlane.setAttribute('scale', '6 6 6')
    videoPlane.setAttribute('height', 1.78)
    camera.appendChild(videoPlane)
    window.addEventListener('focus', () => {
      window.location.reload(true)
    })
    window.addEventListener('blur', () => {
      window.location.reload(true)
    })
    window.addEventListener('resume', () => {
      this.videoSrc.play().catch(e => console.error)
    })
  }
}
