import * as Data from '../database/flow-static.json'
import { hideElem, removeElem, showElem } from '../util/show-hide'

export const EternevaFlow = {
  schema: {
    progress: { type: 'number', default: null }
  },
  init () {
    document.addEventListener('gesturestart', function (e) {
      e.preventDefault()
    })
    // dom elements
    this.showroomGroup = document.getElementById('showroomGroup')
    this.cameraRig = document.getElementById('cameraRig')
    this.animatedModels = Array.from(document.getElementsByClassName('animated'))
    this.holoSrcs = Array.from(document.getElementsByClassName('holoSrc'))
    this.brollSrc = document.getElementById('brollSrc')
    this.holoPlane = document.getElementById('holoPlane')
    this.holoSrc = document.getElementById('holoSrc1')
    this.captionSrc = document.getElementById('captionSrc')
    this.captionSource = this.captionSrc.querySelector('source')
    this.captionTrack = this.captionSrc.querySelector('track')
    this.holoGroup = document.getElementById('holoGroup')
    this.tapIcon = document.getElementById('tapIcon')
    this.videoWall = document.getElementById('videowall')
    this.camera = document.getElementById('camera')
    this.gui = document.getElementById('gui')
    this.socialBlock = document.getElementById('socialBlock')
    this.brandLogo = document.getElementById('finalBrandLogo')
    this.documentModal = document.getElementById('documentModal')
    this.resetPosition = document.getElementById('resetPosition')
    this.playOverlay = document.getElementById('playOverlay')
    this.holoCap = document.getElementById('holoCap')
    this.controls = document.getElementById('controls')
    this.ccVisible = true
    this.timelineDots = Array.from(document.getElementsByClassName('dot'))
    this.videoPlaybackElapsed = document.getElementById('videoPlaybackElapsed')

    // bind methods
    this.popupFactory = this.popupFactory.bind(this)
    this.moveCameraTo = this.moveCameraTo.bind(this)
    this.handleEnded = this.handleEnded.bind(this)
    this.handleCaptionEnded = this.handleCaptionEnded.bind(this)
    this.handleBrollEnd = this.handleBrollEnd.bind(this)
    this.inspectDeskItem = this.inspectDeskItem.bind(this)
    this.approachDesk = this.approachDesk.bind(this)
    this.handlePlay = this.handlePlay.bind(this)
    this.handleTimeUpdate = this.handleTimeUpdate.bind(this)

    // array to store popup timeouts
    this.popupTOs = []

    // always-on event listeners

    // close intro modal
    document.getElementById('continue')
      .addEventListener('click', () => {
        window.dataLayer.push({ event: 'Showroom Entered' })
        hideElem(document.getElementById('introModal'))
        this.el.setAttribute('eterneva-flow', 'progress: 1')
      })

    // close document modal
    this.documentModal.querySelector('i')
      .addEventListener('click', () => {
        hideElem(this.documentModal, () => {
          this.documentModal.querySelector('#innerDocumentModal').innerHTML = ''
        })
      })

    // reset camera position after inspection
    this.resetPosition.addEventListener('click', (e) => {
      this.tapIcon.setAttribute('animation__scl', 'property: scale; from: 0.7 0.7 0.7; to: 0.85 0.85 0.85; dir: alternate; loop: true;')
      this.tapIcon.setAttribute('position', this.progressInfo.tapIconPosition)
      this.tapIcon.querySelector('a-plane').setAttribute('material', { src: '#zoomIconSrc' })
      this.tapIcon.setAttribute('visible', true)
      this.tapIcon.removeEventListener('click', this.inspectDeskItem)
      this.tapIcon.addEventListener('click', this.approachDesk)
      this.moveCameraTo(this.progressInfo.cameraPosition, this.progressInfo.cameraRotation)
      hideElem(this.resetPosition)
    })

    // toggle on/off closed captions
    this.controls.addEventListener('click', () => {
      this.ccVisible = !this.ccVisible
      window.dataLayer.push({ event: `CC toggled ${this.ccVisible ? 'on' : 'off'}` })
      if (this.ccVisible) {
        this.controls.innerHTML = '<i class="fas fa-closed-captioning"></i>'
        if (!this.captionSrc.paused) {
          for (let i = 0; i < this.captionSrc.textTracks.length; i++) {
            this.captionSrc.textTracks[i].mode = 'showing'
            this.captionTrack.setAttribute('mode', 'showing')
          }
        }
      } else {
        this.controls.innerHTML = '<i class="fal fa-closed-captioning"></i>'
        for (let i = 0; i < this.captionSrc.textTracks.length; i++) {
          this.captionSrc.textTracks[i].mode = 'hidden'
          this.captionTrack.setAttribute('mode', 'hidden')
        }
      }
    })

    this.timelineDots.forEach((dot, i) => {
      switch (i) {
        case 0:
          dot.addEventListener('click', () => {
            this.el.setAttribute('eterneva-flow', { progress: 1 })
          })
          break
        case 1:
          dot.addEventListener('click', () => {
            this.el.setAttribute('eterneva-flow', { progress: 2 })
          })
          break
        case 2:
          dot.addEventListener('click', () => {
            this.el.setAttribute('eterneva-flow', { progress: 5 })
          })
          break
        case 3:
          dot.addEventListener('click', () => {
            this.el.setAttribute('eterneva-flow', { progress: 7 })
          })
          break
        case 4:
          dot.addEventListener('click', () => {
            this.el.setAttribute('eterneva-flow', { progress: 9 })
          })
          break
        case 5:
          dot.addEventListener('click', () => {
            this.el.setAttribute('eterneva-flow', { progress: 10 })
          })
          break
        case 6:
          dot.addEventListener('click', () => {
            this.el.setAttribute('eterneva-flow', { progress: 11 })
          })
          break
      }
    })
  },
  update () {
    // prevent autoplay on page load
    let { progress } = this.data
    if (!progress) return
    progress = 'progress' + progress

    // object with progress data
    this.progressInfo = Data[progress]

    // hide show elems
    hideElem(this.socialBlock)
    hideElem(this.brandLogo)
    hideElem(this.resetPosition)
    showElem(this.playOverlay, 'flex')

    // pause any playing broll footage
    this.brollSrc.pause()
    this.brollSrc.currentTime = 0
    this.brollSrc.muted = true
    this.holoSrc.pause()
    this.holoSrc.currentTime = 0
    this.holoSrc.muted = true
    this.captionSrc.pause()
    this.captionSrc.currentTime = 0
    this.captionTrack.default = true
    this.captionSrc.muted = false

    // remove lingering events
    this.holoSrc.removeEventListener('ended', this.handleEnded)
    this.brollSrc.removeEventListener('ended', this.handleBrollEnd)
    this.tapIcon.removeEventListener('click', this.inspectDeskItem)
    this.tapIcon.removeEventListener('click', this.approachDesk)
    this.playOverlay.removeEventListener('click', this.handlePlay)
    this.holoSrc.removeEventListener('timeupdate', this.handleTimeUpdate)

    // clear lingering timeouts
    clearTimeout(this.progressTO)
    Array.from(document.getElementsByClassName('popup'))
      .forEach((popup) => { popup.remove() })
    this.popupTOs.forEach((timeout) => {
      clearTimeout(timeout)
    })

    this.timelineDots.forEach(d => d.querySelector('.circle').classList.remove('active'))
    switch (this.data.progress) {
      case 1:
        this.timelineDots[0].querySelector('.circle').classList.add('active')
        window.dataLayer.push({ event: 'Visited Room 1' })
        break
      case 2: case 3: case 4:
        this.timelineDots[1].querySelector('.circle').classList.add('active')
        window.dataLayer.push({ event: `Visited Room 2 Scene ${this.data.progress - 1}` })
        break
      case 5: case 6:
        this.timelineDots[2].querySelector('.circle').classList.add('active')
        window.dataLayer.push({ event: `Visited Room 3 Scene ${this.data.progress - 4}` })
        break
      case 7: case 8:
        this.timelineDots[3].querySelector('.circle').classList.add('active')
        window.dataLayer.push({ event: `Visited Room 4 Scene ${this.data.progress - 6}` })
        break
      case 9:
        this.timelineDots[4].querySelector('.circle').classList.add('active')
        window.dataLayer.push({ event: 'Visited Room 5' })
        break
      case 10:
        this.timelineDots[5].querySelector('.circle').classList.add('active')
        window.dataLayer.push({ event: 'Visited Room 6' })
        break
      case 11:
        this.timelineDots[6].querySelector('.circle').classList.add('active')
        window.dataLayer.push({ event: 'Visited Room 7' })
        break
    }

    // pause active gltf animations and play specified
    if (this.progressInfo.modelToAnimate) {
      const modelToAnimate = document.getElementById(this.progressInfo.modelToAnimate)
      this.animatedModels.forEach((model) => {
        model.setAttribute('animation-mixer', 'timeScale: 0;')
      })
      modelToAnimate.setAttribute('animation-mixer', 'timeScale: 1;')
    }

    // handle background videos
    if (this.progressInfo.broll) {
      this.brollSrc.currentTime = 0
      if (!this.progressInfo.brollStatic) {
        this.brollSrc.src = `/public/videos/broll/under512/${this.progressInfo.broll}.mp4`
        this.videoWall.setAttribute('bg-videos', { active: this.progressInfo.broll, isStatic: false })
      } else {
        this.videoWall.setAttribute('bg-videos', { active: this.progressInfo.broll, isStatic: true })
      }
    }
    // handle captions
    this.captionSource.src = `/public/videos/captions/${this.progressInfo.holoSrc}.mp3`
    this.captionTrack.src = `/public/videos/captions/${this.progressInfo.holoSrc}.vtt`
    for (let i = 0; i < this.captionSrc.textTracks.length; i++) {
      this.captionSrc.textTracks[i].mode = 'hidden'
      this.captionTrack.setAttribute('mode', 'hidden')
      if (this.ccVisible) {
        this.captionSrc.textTracks[i].mode = 'showing'
        this.captionTrack.setAttribute('mode', 'showing')
      }
    }
    this.captionSrc.load()

    // handle holograms
    // this.holoGroup.setAttribute('visible', false)
    this.holoGroup.setAttribute('scale', '0 0 0')

    /*
    this.holoSrc.src = `/public/videos/holograms/${this.progressInfo.holoSrc}.mp4`
    this.holoSrc.load()
    this.holoGroup.setAttribute('position', this.progressInfo.holoPosition)
    */
    // preload a-asset method
    this.holoSrcs.forEach((video) => {
      video.pause()
      video.currentTime = 0
    })
    this.holoPlane = document.getElementById('holoPlane')
    this.holoPlane.remove()
    const holoPlane = document.createElement('a-plane')
    holoPlane.id = 'holoPlane'
    holoPlane.setAttribute('width', 0.066)
    holoPlane.setAttribute('height', 0.132)
    holoPlane.setAttribute('material', `shader: chromakey; src: #holoSrc${this.progressInfo.holoSrc}; color: 0.1 0.9 0.2; transparent: true;`)
    holoPlane.setAttribute('look-at', '#camera')
    this.holoGroup.setAttribute('position', this.progressInfo.holoPosition)
    this.holoGroup.appendChild(holoPlane)
    this.holoSrc = document.getElementById(`holoSrc${this.progressInfo.holoSrc}`)
    this.holoSrc.addEventListener('timeupdate', this.handleTimeUpdate)
    this.holoGroup.setAttribute('scale', '0 0 0')
    this.holoSrc.play()
      .then(() => {
        this.playOverlay.addEventListener('click', this.handlePlay, { once: true })
        this.holoSrc.pause()
        this.brollSrc.play().then(() => this.brollSrc.pause())
      })
      .catch((e) => {
        console.error(e)
      })

    // handle tap to inspect icon
    if (this.progressInfo.tapIconPosition) {
      this.tapIcon.querySelector('a-plane').setAttribute('material', { src: '#zoomIconSrc' })
      this.tapIcon.setAttribute('animation__scl', 'property: scale; from: 0.7 0.7 0.7; to: 0.85 0.85 0.85; dir: alternate; loop: true;')
      this.tapIcon.setAttribute('position', this.progressInfo.tapIconPosition)
      this.tapIcon.addEventListener('click', this.approachDesk)
      if (this.data.progress !== 11) {
        this.tapIcon.setAttribute('visible', true)
      }
    } else {
      this.tapIcon.setAttribute('visible', false)
    }

    // handle auto-flow for progress10
    if (this.progressInfo.brollAutoProgress) {
      this.brollSrc.addEventListener('ended', this.handleBrollEnd)
    }

    // animate camera to specified viewing position
    this.moveCameraTo(this.progressInfo.cameraPosition, this.progressInfo.cameraRotation)
  },
  popupFactory (popupInfo) {
    if (popupInfo.is3D) {
      const popup = document.createElement('a-plane')
      popup.setAttribute('material', { src: popupInfo.content, transparent: true, depthTest: false, opacity: 0 })
      popup.className = 'cantap'
      popup.setAttribute('animation__opc', { property: 'material.opacity', from: 0, to: 1 })
      popup.setAttribute('scale', '0.06 0.06 0.06')
      // popup.setAttribute('look-at', '#camera')
      popup.setAttribute('position', popupInfo.location)
      if (popupInfo.link) {
        popup.addEventListener('click', () => {
          window.open(popupInfo.link, '_blank')
          if (popupInfo.link.includes('lets-talk')) {
            window.dataLayer.push({ event: 'CTA Tapped Contact' })
          } else if (popupInfo.link.includes('visualizer')) {
            window.dataLayer.push({ event: 'CTA Tapped Visualizer' })
          } else if (popupInfo.link.includes('kit')) {
            window.dataLayer.push({ event: 'CTA Tapped Order Kit' })
          }
        })
      }
      this.showroomGroup.appendChild(popup)
    } else {
      const popup = document.createElement('div')
      if (!popupInfo.nostyle) {
        popup.className = 'popup-styled'
      }
      popup.className += ` popup ${popupInfo.location}`
      popup.style.display = 'none'
      popup.innerHTML = popupInfo.content
      if (popupInfo.link) {
        if (typeof popupInfo.link === 'number') {
          popup.className += ' link'
          popup.addEventListener('click', (e) => {
            this.el.setAttribute('eterneva-flow', `progress: ${popupInfo.link}`)
            removeElem(e.currentTarget)
          })
        } else if (popupInfo.link === 'document') {
          popup.addEventListener('click', (e) => {
            const imgContent = e.currentTarget.querySelector('img').cloneNode(true)
            this.documentModal.querySelector('#innerDocumentModal').appendChild(imgContent)
            showElem(this.documentModal, 'flex')
          })
        } else if (popupInfo.link.includes('https')) {
          popup.addEventListener('click', (e) => {
            window.open(popupInfo.link, '_blank')
          })
        }
      }
      showElem(popup, 'flex')
      this.gui.appendChild(popup)
    }
  },
  moveCameraTo (position, yRotation) {
    const orientation = this.camera.getAttribute('rotation').y % 360
    this.cameraRig.setAttribute('animation__pos', {
      property: 'position',
      to: position
    })
    this.cameraRig.setAttribute('animation__rot', {
      property: 'rotation',
      to: `0 ${yRotation - orientation} 0`
    })
  },
  handleEnded () {
    // this.holoGroup.setAttribute('visible', false)
    this.holoGroup.setAttribute('scale', '0 0 0')
    if (this.data.progress === 11) {
      showElem(this.socialBlock, 'flex')
      showElem(this.brandLogo, 'flex')
      this.tapIcon.setAttribute('visible', true)
    }
    if (this.progressInfo.autoprogress) {
      this.progressTO = setTimeout(() => {
        this.el.setAttribute('eterneva-flow', `progress: ${this.data.progress + 1}`)
        hideElem(this.documentModal, () => {
          this.documentModal.querySelector('#innerDocumentModal').innerHTML = ''
        })
      }, 5000)
    }
  },
  handleCaptionEnded () {
    for (let i = 0; i < this.captionSrc.textTracks.length; i++) {
      this.captionSrc.textTracks[i].mode = 'hidden'
      this.captionTrack.setAttribute('mode', 'hidden')
    }
  },
  inspectDeskItem () {
    const doc = this.progressInfo.tapInspectSrc.replace('eterneva-', '').replace('.jpg', '').replace('.png', '')
    const cap = doc.charAt(0).toUpperCase() + doc.slice(1)
    window.dataLayer.push({ event: `Inspected ${cap} Doc` })
    const imgContent = document.createElement('img')
    imgContent.src = `/public/images/${this.progressInfo.tapInspectSrc}`
    this.documentModal.querySelector('#innerDocumentModal').appendChild(imgContent)
    showElem(this.documentModal, 'flex')
  },
  approachDesk () {
    window.dataLayer.push({ event: 'Desk Approached' })
    if (this.progressInfo.cameraInspectRotation) {
      this.moveCameraTo(this.progressInfo.cameraInspectPosition, this.progressInfo.cameraInspectRotation)
    } else {
      this.moveCameraTo(this.progressInfo.cameraInspectPosition, this.progressInfo.cameraRotation)
    }
    if (this.progressInfo.tapInspectPosition) {
      this.tapIcon.setAttribute('visible', false)
      if (this.progressInfo.tapInspectPosition) {
        this.tapIcon.setAttribute('position', this.progressInfo.tapInspectPosition)
        this.tapIcon.addEventListener('click', this.inspectDeskItem)
      }
      this.tapIcon.removeEventListener('click', this.approachDesk)
    } else {
      this.tapIcon.setAttribute('visible', false)
    }
    setTimeout(() => {
      showElem(this.resetPosition, 'flex')
      if (this.progressInfo.tapInspectPosition) {
        this.tapIcon.querySelector('a-plane').setAttribute('material', { src: '#tapIconSrc' })
        this.tapIcon.setAttribute('animation__scl', 'property: scale; from: 0.4 0.4 0.4; to: 0.7 0.7 0.7; dir: alternate; loop: true;')
        this.tapIcon.setAttribute('visible', true)
      }
    }, 1000)
  },
  handleBrollEnd () {
    this.progressTO = setTimeout(() => {
      this.el.setAttribute('eterneva-flow', `progress: ${this.data.progress + 1}`)
    }, 1000)
  },
  handlePlay () {
    hideElem(this.playOverlay)
    if (this.progressInfo.brollStatic) {
      this.holoSrc.play().then(() => {
        this.holoSrc.addEventListener('ended', this.handleEnded)
        setTimeout(() => {
          // this.holoGroup.setAttribute('visible', true)
          this.holoGroup.setAttribute('scale', '1 1 1')
        }, 1000)
        this.holoSrc.muted = false
        this.captionSrc.play().then(() => {
          this.captionSrc.muted = true
          this.captionSrc.addEventListener('ended', this.handleCaptionEnded)
          // handle pop-ups
          this.progressInfo.popups.forEach((popup) => {
            const to = setTimeout(() => {
              this.popupFactory(popup)
            }, window.desktopDebug ? 0 : popup.timeout)
            this.popupTOs.push(to)
          })
        }).catch((error) => { console.log(error) })
      })
    } else {
      this.holoSrc.play().then(() => {
        this.holoSrc.addEventListener('ended', this.handleEnded)
        setTimeout(() => {
          // this.holoGroup.setAttribute('visible', true)
          this.holoGroup.setAttribute('scale', '1 1 1')
        }, 1000)
        this.holoSrc.muted = false
        this.brollSrc.play().then(() => {
          this.brollSrc.muted = false
          this.captionSrc.play().then(() => {
            this.captionSrc.muted = true
            this.captionSrc.addEventListener('ended', this.handleCaptionEnded)
            // handle pop-ups
            this.progressInfo.popups.forEach((popup) => {
              const to = setTimeout(() => {
                this.popupFactory(popup)
              }, window.desktopDebug ? 0 : popup.timeout)
              this.popupTOs.push(to)
            })
          }).catch((error) => { console.log(error) })
        })
      })
    }
  },
  handleTimeUpdate () {
    this.videoPlaybackElapsed.style.width = `${(this.holoSrc.currentTime / this.holoSrc.duration) * 100}%`
  }
}
